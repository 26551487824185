import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import project1 from '../images/p001.png'
import project2 from '../images/p002.jpg'
import project3 from '../images/p003.png'
import project4 from '../images/p004.png'
import project5 from '../images/p005.png'
import project6 from '../images/p006.png'
import project7 from '../images/p007.png'
import project8 from '../images/p008.png'
import project9 from '../images/p009.png'
import project10 from '../images/p010.png'
import project11 from '../images/p011.png'
import project12 from '../images/p012.png'
import project13 from '../images/p013.png'
import project14 from '../images/p014.png'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: '#233',
    height: '100%',
  },
  cardContainer: {
    maxWidth: 345,
    margin: '3rem auto',
  },
}))

const projects = [
  {
    name: 'Sellege',
    language: 'Flutter, Swift, Kotin, CMS Admin, Twilio API, Chatting',
    description: `I developed a community app for selling things in a hybrid method using Flutter. The mottos of the app is:
"Sell easily with sellege. The first exclusive college marketplace customized for your needs."`,
    image: project1,
    webLink: 'https://sellege.app/',
    androidLink: 'https://play.google.com/store/apps/details?id=com.kyad.cozone',
    iosLink: 'https://apps.apple.com/us/app/sellege/id1555707802',
  },
  {
    name: 'Easy Taxi',
    language: 'Java, Objective-C, Android, iOS, GPS Tracking, Payment',
    description: `When the easy taxi version 1.0 was initially developed, I participated in the project as a native app developer and proceeded with GPS tracking and map interlocking.`,
    image: project2,
    webLink: 'https://cabify.com/en',
    androidLink: 'https://play.google.com/store/apps/details?id=br.com.easytaxi&gl=US',
    iosLink: 'https://apps.apple.com/app/cabify/id476087442',
  },
  {
    name: 'Outofstock',
    language: 'PHP, Codeigniter, NFT token, Java, Swift, Android, iOS, Payment',
    description: `It is an auction site that sells sneakers through an auction system between consumers and sellers.
Developed and released native Android and iPhone apps along with websites.`,
    image: project3,
    webLink: 'https://outofstock.co.kr/',
    androidLink: 'https://play.google.com/store/apps/details?id=kr.co.outofstock',
    iosLink: 'https://apps.apple.com/app/id1441011604',
  },
  {
    name: 'CrazyBar Simulation',
    language: 'Unity, CSharp, Android, iOS, WebSocket, In-app Payment, Plugin, Google Ad, Facebook Share',
    description: `The crazy bar is a pub that operates a store with an exorbitant price than market price.
Today, the dream bar has been opened with 100 billion borrowing money. But to pay off the increasing debt, the bar has to be operated by charging the customer huge amount more than normal price while running the bar.`,
    image: project4,
    webLink: 'http://bar.kyadmeeting.com/link/help_en',
    androidLink: 'https://play.google.com/store/apps/details?id=com.kyad.crazybar&hl=en',
    iosLink: 'https://apps.apple.com/us/app/id1469071658',
  },
  {
    name: 'Neighbourhood Community',
    language: 'Kotlin, Swift, Android, iOS, Chatting, Selling Community',
    description: `We created a second-hand trading app between neighbors that is serviced in China. 
The actual app name in China is "远亲近邻".`,
    image: project5,
    webLink: 'https://yuanqinjinlin.com/',
    androidLink: '',
    iosLink: 'https://apps.apple.com/cn/app/id1578418526',
  },
  {
    name: 'Mofit',
    language: 'Bluetooth, Kotlin, Swift, GPS, Google Map, MQTT, Device Connection',
    description: `A Bluetooth device with a fall detection function was linked with the app, and real-time location sharing and SOS sending to parents were implemented.
"MOFIT" is the best device for children to enjoy leisure sports and mobility activities more and more safely. My child can develop social skills and physical strength by exercising happily with his friends in a wider place at home. I'm your friend who can help you do outdoor activities with peace of mind.`,
    image: project6,
    webLink: '',
    androidLink: 'https://play.google.com/store/apps/details?id=com.kyad.mofit',
    iosLink: 'https://apps.apple.com/us/app/id1565087315',
  },
  {
    name: 'JellyCam',
    language: 'WebRTC, Realtime Video & Audio Chatting, Android, Java, Firebase',
    description: `I developed an app that enables 1:1 video and audio calls between users all over the world using WebRTC and Firebase.
I also developed an iPhone App, but it did not pass the review due to policy issues in the App Store.`,
    image: project7,
    webLink: '',
    androidLink: 'https://play.google.com/store/apps/details?id=com.jelly.cam',
    iosLink: '',
  },
  {
    name: 'Bottakuri Bar',
    language: 'C#, Java, Objective-C, Cocos2D, Android, iOS, Socket Connection',
    description: `This is a bar management virtual simulation game developed with the Cocos2D game engine.
Both Android and iPhone apps have been released.`,
    image: project8,
    webLink: 'https://bottakuribar.com/',
    androidLink: 'https://play.google.com/store/apps/details?id=com.ramsdesign.bottakuri',
    iosLink: 'https://apps.apple.com/app/id1076460598',
  },
  {
    name: 'Memory Travel (Diary)',
    language: 'Kotlin, Swift, Sqlite, Google Drive Backup, Android, iOS, Socket Connection',
    description: `Memory Travel is the the diary app. that you can keep a diary with emotion characters and photos.
After you keep a diary, you can back up the data and move it to another storage device.
And you can print it out as a PDF file or print it out on paper and make it into a book.`,
    image: project9,
    webLink: '',
    androidLink: 'https://play.google.com/store/apps/details?id=com.memory.travel',
    iosLink: 'https://apps.apple.com/app/id1590627092',
  },
  {
    name: 'Verilocal',
    language: 'PHP, MySQL, Flutter, Android, iOS, Chatting, Google Map, Remote Notification',
    description: `The verified local buy and sell. Buy with confidence from verified sellers.`,
    image: project10,
    webLink: 'https://verilocal.ca/',
    androidLink: 'https://play.google.com/store/apps/details?id=com.rosebuddigital.verilocal',
    iosLink: 'https://apps.apple.com/us/app/detail/id6444013402',
  },
  {
    name: 'ChainPerk',
    language: 'Blockchain, NFT, Kubernetes, Docker, GCP, Javascript, Typescript, React',
    description: `Purchase your PERK tokens & NFT to become a mayor of your favorite city, add users that review their experiences, collect your reward and grow your passive income by leveling up your city!`,
    image: project11,
    webLink: 'https://chainperk.com/',
    androidLink: '',
    iosLink: '',
  },
  {
    name: 'ARBUSERS',
    language: 'PHP, PHPBB, Forum, Blog, Nginx, Betting Logic',
    description: `The smart betting community`,
    image: project12,
    webLink: 'https://arbusers.com/',
    androidLink: '',
    iosLink: '',
  },
  {
    name: 'ACTIONCREST',
    language: 'Wordpress, Theme Customize, PHP, Mysql',
    description: `ACTIONCREST Homepage, Sound business guidance for operators, investors, affiliates and entrepreneurs`,
    image: project13,
    webLink: 'https://actioncrest.com/',
    androidLink: '',
    iosLink: '',
  },
  {
    name: 'ARBUSERS Portal',
    language: 'PHP, Portal, Nginx, Wordpress, Theme Customize, Wordpress Plugin',
    description: `The smart betting community, blog, portal`,
    image: project14,
    webLink: 'https://arbusers.com/portal/',
    androidLink: '',
    iosLink: '',
  },
]

const Portfolio = () => {
  const classes = useStyles()
  return (
    <Box component="div" className={classes.mainContainer}>
      <Grid container justify="center">
        {/* Projects */}
        {projects.map((project, i) => (
          <Grid item xs={12} sm={8} md={4} key={i}>
            <Card className={classes.cardContainer}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Project 1"
                  height="340"
                  image={project.image}
                />
                <CardContent>
                  <Typography variant="h5" color={'primary'} gutterBottom>
                    {project.name}
                  </Typography>
                  <Typography variant="body1" color={'secondary'} gutterBottom>
                    {project.language}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {project.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                {project.webLink ? <Button target={'_blank'} href={project.webLink} size="small" color="primary">Web
                  Link</Button> : null}
                {project.androidLink ? <Button target={'_blank'} href={project.androidLink} size="small" color="info">Google
                  Play</Button> : null}
                {project.iosLink ? <Button target={'_blank'} href={project.iosLink} size="small"
                                           color="secondary">AppStore</Button> : null}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Portfolio
