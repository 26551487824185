import React, { useState } from 'react'
import { Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Home from "./components";
import Resume from "./components/Resume";
import Navbar from "./components/Navbar";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";

import "./App.css";

function App() {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <CssBaseline />
      <Navbar open={open} setOpen={setOpen} />
      <Switch>
        <Route exact path="/" render={() => <Home setOpen={setOpen} />} />
        <Route exact path="/resume" component={Resume} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/contact" component={Contact} />
      </Switch>
    </React.Fragment>
  );
}

export default App;
