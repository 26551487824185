import React from "react";
import Header from "./Header";
import Particles from "./Particles";

const Home = (props) => {
  return (
    <React.Fragment>
      <Header setOpen={props.setOpen} />
      <Particles />
    </React.Fragment>
  );
};

export default Home;
