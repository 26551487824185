import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typed from 'react-typed'
import { makeStyles } from '@material-ui/core/styles'
import avatar from '../avatar.png'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: theme.spacing(1),
  },
  title: {
    color: 'tomato',
  },
  subtitle: {
    color: 'tan',
    textTransform: 'uppercase',
  },
  typedContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '100vw',
    textAlign: 'center',
    zIndex: 1,
  },
}))

const Header = (props) => {
  const classes = useStyles()

  return (
    <IconButton className={classes.typedContainer} onClick={() => props.setOpen(true)}>
      <Box>
        <Grid container justify="center">
          <Avatar className={classes.avatar} src={avatar} alt="Filip Radosavljevic"/>
        </Grid>
        <Typography className={classes.title} variant="h4">
          <Typed strings={['Filip Radosavljevic']} typeSpeed={40}/>
        </Typography>

        <Typography className={classes.subtitle} variant="h5">
          <Typed
            strings={[
              'Mobile App Developer',
              'Frontend Developer',
              'Backend Developer',
              'Database Administrator',
            ]}
            typeSpeed={40}
            backSpeed={50}
            loop
          />
        </Typography>
      </Box>
    </IconButton>
  )
}

export default Header
